export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_SHEETS = "SET_SHEETS";
export const SET_EMAILSENT = "SET_EMAILSENT";
export const GET_USERS = "GET_USERS";
export const SET_ADDED_USER = "SET_ADDED_USER";
export const GET_SUCCESS = "GET_SUCCESS"
export const GET_USER_LOGS = "GET_USER_LOGS"
export const CLEAR_USER_LOGS = "CLEAR_USER_LOGS"
export const GET_SHEET_LOGS = "GET_SHEET_LOGS"
export const CLEAR_SHEET_LOGS = "CLEAR_SHEET_LOGS"
export const GET_USER_NAME = "GET_USER_NAME"
export const IS_LOADING = "IS_LOADING"
